
// @ts-nocheck


export const localeCodes =  [
  "es-ES",
  "en-GB"
]

export const localeLoaders = {
  "es-ES": [{ key: "../../../nuxt-zod-i18n/dist/runtime/locales/es-ES.json", load: () => import("../../../nuxt-zod-i18n/dist/runtime/locales/es-ES.json" /* webpackChunkName: "locale__Users_mrk_Documents_latinta_app_node_modules_nuxt_zod_i18n_dist_runtime_locales_es_ES_json" */), cache: true },
{ key: "../../../../../netzo/locales/es-ES.json", load: () => import("../../../../../netzo/locales/es-ES.json" /* webpackChunkName: "locale__Users_mrk_Documents_netzo_locales_es_ES_json" */), cache: true }],
  "en-GB": [{ key: "../../../nuxt-zod-i18n/dist/runtime/locales/en-GB.json", load: () => import("../../../nuxt-zod-i18n/dist/runtime/locales/en-GB.json" /* webpackChunkName: "locale__Users_mrk_Documents_latinta_app_node_modules_nuxt_zod_i18n_dist_runtime_locales_en_GB_json" */), cache: true },
{ key: "../../../../../netzo/locales/en-GB.json", load: () => import("../../../../../netzo/locales/en-GB.json" /* webpackChunkName: "locale__Users_mrk_Documents_netzo_locales_en_GB_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "es-ES",
      "isCatchallLocale": true,
      "language": "es-ES",
      "name": "Español",
      "files": [
        "/Users/mrk/Documents/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/locales/es-ES.json",
        "/Users/mrk/Documents/netzo/locales/es-ES.json"
      ]
    },
    {
      "code": "en-GB",
      "isCatchallLocale": true,
      "language": "en-GB",
      "name": "English",
      "files": [
        "/Users/mrk/Documents/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/locales/en-GB.json",
        "/Users/mrk/Documents/netzo/locales/en-GB.json"
      ]
    }
  ],
  "defaultLocale": "es-ES",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": true,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": [
    {
      "langDir": "/Users/mrk/Documents/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/locales",
      "locales": [
        {
          "code": "en-GB"
        },
        {
          "code": "es-ES"
        }
      ]
    }
  ]
}

export const normalizedLocales = [
  {
    "code": "es-ES",
    "isCatchallLocale": true,
    "language": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "/Users/mrk/Documents/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/locales/es-ES.json"
      },
      {
        "path": "/Users/mrk/Documents/netzo/locales/es-ES.json"
      }
    ]
  },
  {
    "code": "en-GB",
    "isCatchallLocale": true,
    "language": "en-GB",
    "name": "English",
    "files": [
      {
        "path": "/Users/mrk/Documents/latinta-app/node_modules/nuxt-zod-i18n/dist/runtime/locales/en-GB.json"
      },
      {
        "path": "/Users/mrk/Documents/netzo/locales/en-GB.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
